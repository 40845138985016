<template>
  <div class="Device" ref="vlbox">
    <div class="index_main" ref="index_main">
    <div class="index_right">
        <p class="search">
              <el-select v-model="searchForm.status" v-if="permissoin.includes('query')" clearable size="mini">
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        <el-input
        v-if="permissoin.includes('query')"
          type="text" clearable
          size="mini"
          v-model="searchForm.deviceNum"
          placeholder=" 请输入设备号或简称"
        ></el-input>
        <button class="btn1" v-if="permissoin.includes('query')" @click="currentPage2=1;getData()">
          <i class="el-icon-search"></i>
          查询
        </button>
         <!-- <button class="btn2" @click="modal=1">
          <i class="el-icon-plus"></i>
          新增
        </button> -->
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="deviceNum" label="设备号" :show-overflow-tooltip="true"
              width="140">
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" :show-overflow-tooltip="true"
              width="140">
              <template slot-scope="scope">
                <span class="colorblue" style="cursor: pointer;" @click="getDetails(scope.row.id)">{{scope.row.productName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="供应商" :show-overflow-tooltip="true"
              width="140">
            </el-table-column>
            <el-table-column prop="sn" label="设备类型">
              <template slot-scope="scope">
                <span v-if="scope.row.productType == 1">硬件材料</span>
                <span v-if="scope.row.productType == 2">软件系统</span>
                <span v-if="scope.row.productType == 3">其他服务</span>
                <span v-if="scope.row.productType == 4">商务名目</span>
                <span v-if="scope.row.productType == 5">组合包</span>
              </template>
            </el-table-column>
            
            <el-table-column prop="orgName" label="出入状态及时间">
              <template slot-scope="scope">
                <span v-if="scope.row.statusS == 1" >采购入库</span>
                <span v-if="scope.row.statusS == 2" >退件入库</span>
                <span v-if="scope.row.statusS == 3" >残件入库</span>
                <span v-if="scope.row.statusS == 4" >返修入库</span>
                <span v-if="scope.row.statusS == 5" >归还入库</span>
                <span v-if="scope.row.statusS == 6" >组合入库</span>
                <span v-if="scope.row.statusS == 7" >安装出库</span>
                <span v-if="scope.row.statusS == 8" >送修出库</span>
                <span v-if="scope.row.statusS == 9" >借用出库</span>
                <span v-if="scope.row.statusS == 10" >组合出库</span>
                <span v-if="scope.row.statusS== 1" > （{{timestampToTime1(scope.row.inWarehouse)}}）</span>
                <span v-if="scope.row.statusS == 2" > （{{timestampToTime1(scope.row.outWarehouse)}}）</span>
              </template>
            </el-table-column>
            <el-table-column prop="inWarehouseBatch" label="入库批次">
            </el-table-column>
            <el-table-column prop="orgName" label="设备状态">
              <template slot-scope="scope">
                <span v-if="scope.row.deviceStatus == 0" class="colorgreen">正常</span>
                <span v-if="scope.row.deviceStatus == 1" class="colorred">故障</span>
                <span v-if="scope.row.deviceStatus == 2" class="colorred">报废</span>
                <span v-if="scope.row.deviceStatus == 2">
                    <span v-if="scope.row.scrapOrigin == 0">自然损坏({{scope.row.nickName}})</span>
                <span v-if="scope.row.scrapOrigin == 1">人为损坏({{scope.row.nickName}})</span>
                <span v-if="scope.row.scrapOrigin == 2">遗失({{scope.row.nickName}})</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="unitPrice" v-if="permissoin.includes('unitPrice')" label="采购价">
            </el-table-column>
            <el-table-column prop="orgName" label="发票类型/税率">
              <template slot-scope="scope">
                <span v-if="scope.row.invoiceType== 1">无票</span>
                <span v-if="scope.row.invoiceType == 2">普通发票</span>
                <span v-if="scope.row.invoiceType == 3">增值税发票</span>
                <span v-if="scope.row.invoiceType != 1">
                    <!-- <span v-if="scope.row.taxRate == 3">6%</span>
                    <span v-if="scope.row.taxRate == 4">9%</span>
                    <span v-if="scope.row.taxRate == 5">13%</span>
                <span v-if="scope.row.taxRate == 1">1%</span>
                <span v-if="scope.row.taxRate == 2">3%</span> -->

                <span v-if="scope.row.taxRate">（{{scope.row.taxRate*100+'%'}}）</span>
                </span>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          prev-text="上一页"
          next-text="下一页"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>

    </div>
    <div class="indexModal" v-if="indexModal == 1">
      <div class="inner">
        <p class="title">
          <span>出入库记录</span>
          <span
            @click="
              indexModal = 0;
              littleForm = {};
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="modal_item3">
             <el-timeline>
    <el-timeline-item
      v-for="(activity, index) in activities"
      :key="index"
      :timestamp="timestampToTime1(activity.dateTime)">
     <p>{{activity.productName}} · {{activity.deviceNum}}
       </p> 
       <p>
         <span>{{activity.genre==1?'入库':'出库'}}</span> · 
         <span v-if="activity.genre==1">
            <span v-if="activity.type == 1" >采购入库</span>
                <span v-if="activity.type == 2" >退件入库</span>
                <span v-if="activity.type == 3" >残件入库</span>
                <span v-if="activity.type == 4" >返修入库</span>
                <span v-if="activity.type == 5" >归还入库</span>
                <span v-if="activity.type == 6" >组合入库</span>
                <span v-if="activity.type == 0" >其他</span>
         </span>
         <span v-if="activity.genre==2">
            <span v-if="activity.type == 0" >其他</span>
                <span v-if="activity.type  == 1" >安装出库</span>
                <span v-if="activity.type  == 2" >送修出库</span>
                <span v-if="activity.type  == 3" >借用出库</span>
                <span v-if="activity.type  == 4" >组合出库</span>
         </span>
       </p>
    </el-timeline-item>
  </el-timeline>
 
        </div>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import {
  deviceStoreGetList,inAndOutRecord
} from "@/api/apis.js";
import {
  timestampToTime1
} from "@/utils/getTime.js";
export default {
  data() {
    return {
      options: [],
      form: {state:0},
      info: 1,
      timestampToTime1,
      currentPage2:1,
      treeData:[],
      indexModal:0,
      hide:1,
      modal:0,
      treeList:[],
      total:0,
      activities: [{
          content: '活动按期开始',
          timestamp: '2018-04-15'
        }, {
          content: '通过审核',
          timestamp: '2018-04-13'
        }, {
          content: '创建成功',
          timestamp: '2018-04-11'
        }],
      searchForm:{},
      permissoin:[],
      projectP:-1,
      dialogVisible:false,
      netArrUpdate:[{id:0}],
      ruleForm:{
        pwd:123456
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      timeOut:0,
      treedata:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      option1: [{
          value: '1',
          label: '硬件材料'
        }, {
          value: '2',
          label: '软件系统'
        },{
          value: '3',
          label: '其他服务'
        },{
          value: '4',
          label: '商务名目'
        },{
          value: 5,
          label: '组合包'
        }],
        option2: [{
          value: '1',
          label: '设备入库'
        }, {
          value: '2',
          label: '设备出库'
        }],
      tableData: [],
        rules: {
          orgId: [
            { required: true, message: '选择机构', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={
        status: this.searchForm.status,
        deviceNum: this.searchForm.deviceNum,
        pageNum: this.currentPage2,
        pageSize: 10,
      }
      deviceStoreGetList(data).then(res=>{
        if(res.code==200){
          this.tableData=res.data.list
          this.total=res.data.total-0
        }else{
          this.tableData=[]
          this.total=0
        }
      })
    },
    getDetails(id){
        inAndOutRecord({ id}).then(
          (res) => {
            if (res.code == 200) {
              this.indexModal = 1;
              this.activities=res.data.list
            } else {
              this.$message.error(res.data.msg);
            }
          }
        );
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
  },
  mounted() {
    this.getData()
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)    
      }
    })
  },
  
};
</script>

<style lang="less" >
.Device{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 208px;
        margin-right: 20px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .indexModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1100;
    display: flex;
    align-items: center;
    .inner {
      width: 450px;
      min-height: 400px;
      max-height: 800px;
      overflow: auto;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 1px 1px 10px #888888;
      position: relative;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      .modal_item3 {
        padding: 0 40px;
        margin: 24px 0;
        .input_item {
          display: flex;
          margin-bottom: 13.5px;
          line-height: 28px;
          p {
            width: 80px;
            
          }
          .el-input {
            width: 280px;
          }
        }
      }
      .foot {
        width: 100%;
        position: absolute;
        bottom: 24px;
        .finish {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #d62829;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
        .confirm_delete {
          display: block;
          width: 350px;
          height: 40px;
          border-radius: 6px;
          background-color: #ff0101;
          color: #fff;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>